header{
    background-color: #eeebeb;
    padding: 20px;
    border-bottom: 2px solid #ffffff;
    color: #000000;
}
h1{
    font-size: 36px;
    /* font-family: Arial, sans-serif; */
    text-transform: capitalize;
    letter-spacing: 2px;
    margin: 0px;
    text-align: center;
}

.icon{
    padding: 0 5px;
}
.prjbtn{
    display: flex;
    justify-content: space-between;
}
.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center !important;
}
.dashcontainer{
    border-radius: 10px !important;
    box-shadow:none !important;
    border: none !important;
    border-image: initial !important;
    border-radius: 11px !important;
}

.card {
  padding: 5px;
    /* width: 18rem; */
    border-radius: 11px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-body {
    padding: 1.5rem;
  }
  
  .card-title {
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;
    text-align: start;
    text-transform: capitalize;
  }
  
  .card-text {
    color: #000000;
    font-size: 18px;
    text-align: start;
    text-transform: capitalize;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
.flex{
  display: flex;
  justify-content: center;
  margin-top: 3%;
    margin-bottom: 3%;
}  
.flexbtn{
  display: flex;
  justify-content: center;
  margin-top: 2% !important;
  margin-bottom: 2%;

}
.modalsubbutton {
  display: inline-block !important;
  padding: 10px 20px !important;
  font-size: 16px !important ;
  text-align: center !important;
  text-decoration: none !important;
  border: 1px solid #000000 !important;
  color: #fff !important;
  background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important; 
  border-radius: 5px !important;
  transition: background-color 0.3s, color 0.3s !important;
  margin-top: 3%;
  margin-bottom: 4%;
}
.scrollRow{
  width: 100% !important;
}
.dbutton{
  background: none !important;
    border: none !important;
    color: #000000 !important;
}
.ddrop{
  position: absolute !important;
    top: 4% !important;
    right: 3% !important;
    font-size: 41px;
}
.dropdown-toggle::after {
  display: none !important;
}


.padd{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


@media(max-width:767px) {
  .row {
    --bs-gutter-x: 0rem !important;
  }
}



/* //pagination // */

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 10px 0;
  margin: 20px 0;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  display: block;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
  transition: all 0.3s ease;
}

.pagination li a:hover {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.pagination .selected a {
  background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important;
  color: #ffffff !important;
  border-color: #007bff;
  font-weight: bold;
}

.pagination .disabled a {
  color: #ddd;
  cursor: not-allowed;
}

.pagination .break a {
  cursor: default;
  border: none;
  background: transparent;
}

.pagination li a:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.copydiv{
  position: relative;
    right: 40px;
    top: 20px;
}