.card{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    max-width: 500px;
    width: 100%;
}
.divlabel {
    text-align: start;
    padding-bottom: 2%;
}

.colcard{
    justify-content: center;
    display: flex;

}
.square-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .square {
    background-color: #ccc; 
    border: 1px solid #333; 
  }
  .styled-heading {
    font-size: 2rem;
    color: #333; 
    background-color: #f0f0f0; 
    border-radius: 8px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    display: inline-block; 
    padding: 0.5rem 1rem; 
  }
  .dimension-info {
    font-size: 1rem; 
    color: #555; 
    margin-top: 0.5rem; 
  }
  